// Generated by Framer (1249b65)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./BoJgeiBHZ-0.js";

const cycleOrder = ["ghTMC3n9R", "jwdtzoqyV"];

const variantClassNames = {ghTMC3n9R: "framer-v-1hyzp88", jwdtzoqyV: "framer-v-ea22nl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {NTHK784vL: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "ghTMC3n9R", "Variant 2": "jwdtzoqyV"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, b8jvhMmQ9: title ?? props.b8jvhMmQ9 ?? "fsfjkdsdfs", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ghTMC3n9R"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, b8jvhMmQ9, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ghTMC3n9R", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap4r3llx = activeVariantCallback(async (...args) => {
setVariant("jwdtzoqyV")
})

const onTap1hpwxxu = activeVariantCallback(async (...args) => {
setVariant("ghTMC3n9R")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-ZOCvx", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1hyzp88", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"ghTMC3n9R"} ref={ref} style={{...style}} {...addPropertyOverrides({jwdtzoqyV: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-rw5s5p"} data-framer-name={"switch c"} data-highlight layoutDependency={layoutDependency} layoutId={"QvY1Z1hqu"} onTap={onTap4r3llx} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 34, borderBottomRightRadius: 34, borderTopLeftRadius: 34, borderTopRightRadius: 34}} variants={{jwdtzoqyV: {backgroundColor: "rgb(0, 0, 0)"}}} {...addPropertyOverrides({jwdtzoqyV: {onTap: onTap1hpwxxu}}, baseVariant, gestureVariant)}><motion.div className={"framer-1ilg08w"} layoutDependency={layoutDependency} layoutId={"pCchuG9cJ"} style={{backgroundColor: "rgb(0, 0, 0)", borderBottomLeftRadius: "50%", borderBottomRightRadius: "50%", borderTopLeftRadius: "50%", borderTopRightRadius: "50%"}} variants={{jwdtzoqyV: {backgroundColor: "rgb(255, 255, 255)"}}}/></motion.div><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>fsfjkdsdfs</motion.p></React.Fragment>} className={"framer-sn0b3o"} layoutDependency={layoutDependency} layoutId={"xpzoZ1W_s"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={b8jvhMmQ9} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({jwdtzoqyV: {children: <React.Fragment><motion.p>fsfjkdsdfs</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-ZOCvx [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ZOCvx .framer-bdjant { display: block; }", ".framer-ZOCvx .framer-1hyzp88 { height: 113px; overflow: hidden; position: relative; width: 208px; }", ".framer-ZOCvx .framer-rw5s5p { cursor: pointer; flex: none; height: 34px; left: 0px; overflow: hidden; position: absolute; top: 2px; width: 60px; will-change: var(--framer-will-change-override, transform); }", ".framer-ZOCvx .framer-1ilg08w { bottom: 4px; flex: none; height: 26px; left: 4px; overflow: hidden; position: absolute; width: 26px; will-change: var(--framer-will-change-override, transform); }", ".framer-ZOCvx .framer-sn0b3o { flex: none; height: 31px; left: 73px; position: absolute; top: 60px; white-space: pre-wrap; width: 85px; word-break: break-word; word-wrap: break-word; }", ".framer-ZOCvx.framer-v-ea22nl .framer-1ilg08w { left: 30px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 113
 * @framerIntrinsicWidth 208
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"jwdtzoqyV":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"b8jvhMmQ9":"title"}
 * @framerImmutableVariables false
 */
const FramerBoJgeiBHZ: React.ComponentType<Props> = withCSS(Component, css, "framer-ZOCvx") as typeof Component;
export default FramerBoJgeiBHZ;

FramerBoJgeiBHZ.displayName = "c";

FramerBoJgeiBHZ.defaultProps = {height: 113, width: 208};

addPropertyControls(FramerBoJgeiBHZ, {variant: {options: ["ghTMC3n9R", "jwdtzoqyV"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, b8jvhMmQ9: {defaultValue: "fsfjkdsdfs", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerBoJgeiBHZ, [])